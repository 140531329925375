<template>
     <st-page :title="$t('ISSUED_DOCUMENT.TITLE')">
        <loader v-if="isLoading" />
        <template #toolbar>
            <issued-document-list-toolbar />
        </template>
        <div v-if="initLoading">
            <issued-document-list-filter ref="issuedDocumentListFilter">
                <issued-document-list-table @update="updateList"/>
            </issued-document-list-filter>
        </div>

        <select-organisation-modal
            ref="selectOrganisationModal"
            @organisationSelected="organisationSelected"
        ></select-organisation-modal>
    </st-page>
</template>

<script>
import { mapGetters } from "vuex";
import IssuedDocumentListToolbar from "@/modules/issued-document/components/IssuedDocumentListToolbar.vue";
import SelectOrganisationModal from '@/modules/administrator/components/modals/SelectOrganisationModal.vue';
import Roles from '@/security/roles';

export default {
    name: "IssuedDocumentList",
    components: {
        IssuedDocumentListToolbar,
        IssuedDocumentListFilter: () => import("@/modules/issued-document/components/IssuedDocumentListFilter.vue"),
        IssuedDocumentListTable: () => import("@/modules/issued-document/components/IssuedDocumentListTable.vue"),
        SelectOrganisationModal
    },
    data() {
        return {
            initLoading: false,
        }
    },
    computed: {
        ...mapGetters({
            loading: 'shared/loading',
            organisation: 'administrator/institution',
            currentUser: 'auth/currentUser',
        }),
        loadingFetch() {
            return this.loading['issuedDocument/get'];
        },
        isLoading() {
            return this.loadingFetch;
        },
        isSuperAdmin() {
            return this.currentUser.user_type_name === Roles.values['super_admin']
        }
    },
    mounted() {
       if (this.isSuperAdmin) {
            this.initLoading = false;
            this.$refs.selectOrganisationModal.show();
        } else {
            this.initLoading = true;
        }
    },
    methods: {
        updateList() {
            this.$refs.issuedDocumentListFilter.refresh();
        },
        async organisationSelected() {
            this.$refs.selectOrganisationModal.hide();
            this.initLoading = true;
        },
    },
};
</script>
