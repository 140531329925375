<template>
    <div>
        <!-- TBD -->
    </div>
</template>

<script>
    export default {
        name: 'IssuedDocumentListToolbar',
        methods: {
            // TBD
        }
    };
</script>
